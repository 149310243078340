const KEY_SESSIONSTORAGE_NOTIFICATION_READ_PRIME = "wfPrimeNotificationRead";
const KEY_SESSIONSTORAGE_NOTIFICATION_READ_COUPON = "wfCouponNotificationRead";
const tabletMatchList = window.matchMedia("(min-width: 768px)");
const desktopMatchList = window.matchMedia("(min-width: 1440px)");
const wideMatchList = window.matchMedia("(min-width: 1600px)");

// adding `window.Warframe` singleton to catch anything that gets registered to `window.Warframe` before this runs
window.Warframe = window.Warframe || {};

function addDataLayerListener({element, event, payload, transform}) {
    element.addEventListener(event, (e) => {
        const p = {
            ...payload,
            authentication_status: document.querySelector('body').classList.contains('logged-in') ? 1 : 0,
        };
        transform && transform(element, p);
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(p);
    });
}
function addDataLayerTrigger({selector, event, payload, transform}) {
    const elements = document.querySelectorAll(selector);
    if (elements && elements.length > 0) {
        for (let i = 0; i < elements.length; i ++) {
            addDataLayerListener({
                element: elements[i],
                event,
                payload,
                transform
            });
        }
    }
}

const Warframe = {
    isHeaderActive: () => {
        return !!document.querySelector(".HeaderNavigationContainer");
    },
    isMobile: () => {
        return !tabletMatchList.matches;
    },
    isTablet: () => {
        return tabletMatchList.matches;
    },
    isDesktop: () => {
        return desktopMatchList.matches;
    },
    isWide: () => {
        return wideMatchList.matches;
    },
    triggerLoginLightbox: () => {
        console.error("Attempted to trigger login before script was ready!");
    },
    triggerSignupLightbox: () => {
        console.error("Attempted to trigger signup before script was ready!");
    },
    addDataLayerListener,
    addDataLayerTrigger,
    lockPageScrolling: () => {
        document.querySelector('body').classList.toggle('scroll-disabled', true);
    },
    unlockPageScrolling: () => {
        document.querySelector('body').classList.toggle('scroll-disabled', false);
    },
};


let isPrimeNotificationActive = false;
let isCouponNotificationActive = false;
Warframe.setUserData = (displayName, regionalRestrictions, accountData={}) => {
    // Stop if header is not present
    if (!Warframe.isHeaderActive()) return;

    // Inject player name and mastery rank
    document.querySelector('.HeaderUserProfile-nameText').innerText = displayName;
    let rank = accountData.masteryRank || 0;
    if (rank > 30) {
        rank -= 30;
        document.querySelector('.HeaderUserProfile').classList.add('is-legendary');
        document.querySelector('.HeaderUserProfile-legendary .HeaderUserProfile-masteryNumber').innerText = rank;
    } else {
        document.querySelector('.HeaderUserProfile-mastery .HeaderUserProfile-masteryNumber').innerText = rank;
    }
    // Show player avatar
    if (accountData.avatar) {
        document.querySelector('.HeaderUserProfile-avatar').src = accountData.avatar;
    }
    // Show player plat count in profile
    const platFields = document.querySelectorAll('.HeaderUserProfile-platinumNumber');
    window.forEachNode(platFields, (field) => {
        field.innerText = (accountData.platinum || 0).toLocaleString();
    });
    // Twitch linking
    if (accountData.twitchLinked) {
        document.querySelector('.HeaderUserMenu-twitch').classList.add('is-linked');
    } else {
        document.querySelector('.HeaderUserMenu-twitch').classList.add('is-unlinked');
    }

    if(accountData.crossSave) {
        document.querySelector('.HeaderUserMenu-crossSave').classList.add('is-enabled');
    } else {
        document.querySelector('.HeaderUserMenu-crossSave').remove();
    }

    let isDotShowing = false;
    
    // // Hide prime gaming in KR and RU
    // if (regionalRestrictions && (regionalRestrictions.prime_gaming || regionalRestrictions.prime_gaming_notifications)) {
    //     document.querySelector('.HeaderUserMenu-primeGaming').classList.add('is-hidden');
    // } else {
    //     // Prime Gaming linking
    //     if (accountData.amazonLinked) {
    //         const primeRow = document.querySelector('.HeaderUserMenu-primeGaming');
    //         primeRow.classList.add('is-linked');
    //         if (accountData.amazonAvailable) {
    //             primeRow.classList.add('is-claimable');
    //             if (!sessionStorage.getItem(KEY_SESSIONSTORAGE_NOTIFICATION_READ_PRIME)) {
    //                 isPrimeNotificationActive = true;
    //                 primeRow.classList.add('is-highlighted');
    //                 isDotShowing = true;
    //             }
    //         } else if (accountData.amazonRedeemable) {
    //             primeRow.classList.add('is-redeemable');
    //             if (!sessionStorage.getItem(KEY_SESSIONSTORAGE_NOTIFICATION_READ_PRIME)) {
    //                 isPrimeNotificationActive = true;
    //                 primeRow.classList.add('is-highlighted');
    //                 isDotShowing = true;
    //             }
    //         }
    //     } else {
    //         document.querySelector('.HeaderUserMenu-primeGaming').classList.add('is-unlinked');
    //     }
    // }

    // Show plat count
    document.querySelector('.HeaderUserMenu-platinumNumber').innerText = (accountData.platinum || 0).toLocaleString();

    // Show coupon line if coupon active
    if (accountData.coupon && accountData.coupon.active) {
        /*
        for (const couponKey of Object.keys(accountData.coupons)) {
            const coupon = accountData.coupons[couponKey];
            if (coupon && coupon.Amount) {
                document.querySelector('.HeaderUserMenu-coupon').classList.add('is-active');
                document.querySelector('.HeaderUserMenu-couponPercent').innerText = coupon.Amount;
                isDotShowing = true;
                break;
            }
        }
        */

        let couponRow = null;
        if (accountData.coupon.discount) {
            couponRow = document.querySelector('.HeaderUserMenu-couponDiscount');
            couponRow.classList.add('is-active');
            document.querySelector('.HeaderUserMenu-couponDiscountPercent').innerText = accountData.coupon.discount;
        } else if(accountData.coupon.multiplier) {
            couponRow = document.querySelector('.HeaderUserMenu-couponMultiplier');
            couponRow.classList.add('is-active');
            document.querySelector('.HeaderUserMenu-couponMultiplierAmount').innerText = Math.round((accountData.coupon.multiplier-1)*100);
        }

        if (!sessionStorage.getItem(KEY_SESSIONSTORAGE_NOTIFICATION_READ_PRIME)) {
            isCouponNotificationActive = true;
            couponRow && couponRow.classList.add('is-highlighted');
            isDotShowing = true;
        }

    }
    if (isDotShowing) {
        document.querySelector('.HeaderUserProfile').classList.add('is-notification-active');
    }

    // Resync header after update
    Warframe.syncHeader && Warframe.syncHeader();
};

if (Warframe.isHeaderActive()) {
    // Header adaptive responsiveness
    const headerContainerElem = document.querySelector(".HeaderNavigationContainer");
    const headerNavBarElem = document.querySelector(".HeaderNavigationBar");
    const headerLinkContainerElem = document.querySelector(".HeaderNavigationBar-desktopMenuContainer");
    const headerLinksElem = document.querySelector(".HeaderDesktopMenu");
    const headerControlsContainerElem = document.querySelector(".HeaderNavigationBar-controlsContainer");
    const headerControlsElem = document.querySelector(".HeaderNavigationControls");

    let syncHeaderDebounce = null;
    const headerSyncHandler = () => {
        if (syncHeaderDebounce != null) {
            clearTimeout(syncHeaderDebounce);
        }
        syncHeaderDebounce = setTimeout(syncHeader, 50);
    }
    const syncHeader = () => {
        requestAnimationFrame(() => {
            headerContainerElem.classList.remove('is-collapsed', 'is-mobile', 'is-desktop');
            headerNavBarElem.classList.remove('is-collapsed', 'is-mobile', 'is-desktop');
            if (headerLinksElem.offsetWidth > headerLinkContainerElem.offsetWidth) {
                headerContainerElem.classList.add('is-collapsed');
                headerNavBarElem.classList.add('is-collapsed');

                if (headerControlsElem.offsetWidth > headerControlsContainerElem.offsetWidth) {
                    headerContainerElem.classList.add('is-mobile');
                    headerNavBarElem.classList.add('is-mobile');
                }
            } else {
                headerContainerElem.classList.add('is-desktop');
                headerNavBarElem.classList.add('is-desktop');
            }
        });
    };
    window.addEventListener('resize', headerSyncHandler);
    window.addEventListener('load', syncHeader);
    Warframe.syncHeader = syncHeader;

    function clearUserNotifications() {
        if (isPrimeNotificationActive) {
            sessionStorage.setItem(KEY_SESSIONSTORAGE_NOTIFICATION_READ_PRIME, "true");
            document.querySelector('.HeaderUserMenu-primeGaming').classList.remove('is-highlighted');
            isPrimeNotificationActive = false;
        }
        if (isCouponNotificationActive) {
            sessionStorage.setItem(KEY_SESSIONSTORAGE_NOTIFICATION_READ_COUPON, "true");
            document.querySelector('.HeaderUserMenu-coupon').classList.remove('is-highlighted');
            isCouponNotificationActive = false;
        }
        document.querySelector('.HeaderUserProfile').classList.remove('is-notification-active');
    }

    // User menu hovers
    let hideMenuDebounce = null;

    function userMenuHoverHandler(e) {
        if (!headerNavBarElem.classList.contains('is-collapsed')) {
            e.stopPropagation();
            headerNavBarElem.classList.toggle('is-user-open', true);
            hideMenuDebounce && clearTimeout(hideMenuDebounce);
        }
    }

    document.querySelector('.HeaderUserProfile').addEventListener('mouseenter', userMenuHoverHandler);
    document.querySelector('.HeaderUserMenu').addEventListener('mouseenter', userMenuHoverHandler);

    function userMenuBlurHandler(e) {
        if (!headerNavBarElem.classList.contains('is-collapsed')) {
            e.stopPropagation();
            hideMenuDebounce && clearTimeout(hideMenuDebounce);
            hideMenuDebounce = setTimeout(() => {
                headerNavBarElem.classList.toggle('is-user-open', false);
                clearUserNotifications();
            }, 50);
        }
    }

    document.querySelector('.HeaderUserProfile').addEventListener('mouseleave', userMenuBlurHandler);
    document.querySelector('.HeaderUserMenu').addEventListener('mouseleave', userMenuBlurHandler);

    // User menu toggle
    document.querySelector('.HeaderUserProfile').addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation();
        const open = headerNavBarElem.classList.toggle('is-user-open');
        open && headerNavBarElem.classList.toggle('is-open', false);
        !open && clearUserNotifications();
    });
    // Mobile menu toggle
    document.querySelector('.HeaderNavigationBar-mobileMenuToggleInner').addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation();
        const open = headerNavBarElem.classList.toggle('is-open');
        open && headerNavBarElem.classList.toggle('is-user-open', false);
    });
    // Mobile menu overlay toggle
    document.querySelector('.HeaderNavigationBar-mobileMenuOverlay').addEventListener('click', (e) => {
        e.preventDefault()
        e.stopPropagation();
        if (headerNavBarElem.classList.contains('is-user-open')) {
            clearUserNotifications();
        }
        headerNavBarElem.classList.remove('is-open', 'is-user-open');
    });

    // Nav link handlers
    const navLinks = document.querySelectorAll(".HeaderDesktopMenu .HeaderMenuItem");

    // Clear locked dropdown (desktop)
    const navigationLinkClearCurrent = (resetCurrentToOriginal = false) => {
        for (let i = 0; i < navLinks.length; i++) {
            navLinks[i].classList.toggle('is-current', resetCurrentToOriginal && navLinks[i].classList.contains('is-original'));
            navLinks[i].classList.remove('is-open');
        }
    };

    // Locks underline to clicked node
    const navigationLinkClickHandler = (elem, event) => {
        navigationLinkClearCurrent();
        elem.classList.add('is-current');
    }
    // Lock open desktop nav dropdown
    const navigationDropdownClickHandler = (elem, event) => {
        event && event.preventDefault();
        event && event.stopPropagation();
        if (elem.classList.contains('is-open')) {
            const original = document.querySelector('.HeaderDesktopMenu .HeaderMenuItem.is-original');
            for (let i = 0; i < navLinks.length; i++) {
                navLinks[i].classList.remove('is-open', 'is-current', 'is-closed');
            }
            original && original.classList.add('is-current');
        } else {
            for (let i = 0; i < navLinks.length; i++) {
                const isSelected = navLinks[i] === elem;
                navLinks[i].classList.toggle('is-open', isSelected);
                navLinks[i].classList.toggle('is-current', isSelected);
                navLinks[i].classList.toggle('is-closed', !isSelected);
            }
        }
    }

    // Mobile collapsible handlers
    const mobileNavLinks = document.querySelectorAll(".HeaderMobileMenu .HeaderMenuItem");
    for (let i = 0; i < mobileNavLinks.length; i++) {
        let mobileNavLink = mobileNavLinks[i];
        let mobileNavLinkToggle = mobileNavLink.querySelector('.HeaderMenuItem-label');
        if (mobileNavLink.classList.contains('HeaderMenuItem--dropdown') && mobileNavLinkToggle) {
            mobileNavLinkToggle.addEventListener('click', (e) => {
                e.stopPropagation();
                e.preventDefault();
                mobileNavLink.classList.toggle('is-open');
            });
        }
    }


    // Page nav load handler
    window.PageComponents.afterLoad(() => {
        navLinks.forEach((navLink) => {
            const dropdown = navLink.querySelector('.HeaderMenuItem-dropdown');
            if (dropdown) {
                dropdown.addEventListener("click", navigationLinkClickHandler.bind(null, navLink));
                navLink.querySelector('.HeaderMenuItem-label').addEventListener("click", navigationDropdownClickHandler.bind(null, navLink));
            }
            if (navLink.classList.contains('is-current')) {
                navLink.classList.remove('is-loading');
            }
        });

        // Nav takeover handler
        const LOCALSTORAGE_TAKEOVER_KEY = "Warframe_TakeoverClosed";
        // Change this with every new campaign
        const LOCALSTORAGE_TAKEOVER_VALUE = "WITW";
        const takeoverElem = document.getElementById("header-takeover");

        function forceStickyNavPosition() {
            document.documentElement.scrollTop += 1;
            document.documentElement.scrollTop -= 1;
        }

        if (takeoverElem && localStorage.getItem(LOCALSTORAGE_TAKEOVER_KEY) !== LOCALSTORAGE_TAKEOVER_VALUE) {
            takeoverElem.classList.add('is-primed');

            function dismissTakeover() {
                localStorage.setItem(LOCALSTORAGE_TAKEOVER_KEY, LOCALSTORAGE_TAKEOVER_VALUE);
                takeoverElem.classList.remove('is-active');
                setTimeout(() => {
                    takeoverElem.classList.remove('is-primed');
                    forceStickyNavPosition();
                }, 1000);

            }

            // Disable takeover if any link is clicked
            window.forEachNode(
                takeoverElem.querySelectorAll('.HeaderTakeover-link'),
                (link) => {
                    link.addEventListener('click', dismissTakeover)
                }
            )
            const takeoverCloser = takeoverElem.querySelector('.HeaderTakeover-closer');
            if (takeoverCloser) {
                takeoverCloser.addEventListener('click', () => {
                    dismissTakeover();
                });
            }
            setTimeout(() => {
                    takeoverElem.classList.add('is-active');

                    setTimeout(() => {
                        forceStickyNavPosition();
                    }, 1005);
                }, 1000);
        }
    });
}

// Footer language dropdown handlers
const footerLanguageDropdown = document.getElementById('footer-language-dropdown');
if (footerLanguageDropdown) {
    footerLanguageDropdown.addEventListener('click', (e) => {
        e.stopPropagation();
        footerLanguageDropdown.classList.toggle('is-open');
    });

    document.addEventListener('click', (e) => {
        footerLanguageDropdown.classList.remove('is-open');
    });
}

const footerNavBarElem = document.querySelector(".FooterNavigationBar");
const footerLinkContainerElem = document.querySelector(".FooterNavigationBar-linkContainer");
const footerLinksElem = document.querySelector(".FooterNavigationBar-links");
const footerDropdownContainerElem = document.querySelector(".FooterNavigationBar-dropdownContainer");
if (footerNavBarElem) {
    let syncFooterDebounce = null;
    const footerLinkBarSyncHandler = () => {
        if (syncFooterDebounce != null) {
            clearTimeout(syncFooterDebounce);
        }
        syncFooterDebounce = setTimeout(syncFooterLinkBar, 50);
    }
    const syncFooterLinkBar = () => {
        requestAnimationFrame(() => {
            if (Warframe.isTablet()) {
                footerNavBarElem.classList.add('is-expanded');
                if (footerLinksElem.getBoundingClientRect().right > footerDropdownContainerElem.getBoundingClientRect().left) {
                    footerNavBarElem.classList.remove('is-expanded');
                }
            } else {
                footerNavBarElem.classList.toggle('is-expanded', false);
            }
        });
    };
    window.addEventListener('resize', footerLinkBarSyncHandler);
    window.addEventListener('load', syncFooterLinkBar);
}

// Show time-locked nav elements
const timelocked = document.querySelector('.HeaderMenuItem-live.is-hidden');
const now = Date.now();
if (timelocked) {
    if (timelocked.getAttribute('data-show-override') === "1" || (
        timelocked.getAttribute('data-show-from') <= now
        && now <= timelocked.getAttribute('data-show-until')
    )) {
        timelocked.classList.remove('is-hidden');
    }
}

// Hook up analytics triggers
if (PageComponents) {
    function parsePayload(raw) {
        const tokens = raw.split(',');
        const payload = {};
        for (const token of tokens) {
            if (token) {
                const pair = token.split(':');
                if (pair.length > 1) {
                    payload[pair[0]] = pair[1];
                } else {
                    payload[token] = token;
                }
            }
        }
        return payload;
    }

    PageComponents.afterLoad(() => {
        const AnalyticsElements = document.querySelectorAll("[data-analytics-event]");
        if (AnalyticsElements && AnalyticsElements.length > 0) {
            window.forEachNode(AnalyticsElements, (element) => {
                const event = element.getAttribute("data-analytics-event");
                if (event) {
                    let payload = {};
                    if (element.hasAttribute("data-analytics-payload")) {
                        try {
                            payload = {...payload, ...parsePayload(element.getAttribute("data-analytics-payload"))};
                        } catch (e) {
                            console.warn("(Analytics) Could not parse payload attribute: " + e, element);
                        }
                    }
                    if (element.hasAttribute("data-analytics-name")) {
                        payload["event"] = element.getAttribute("data-analytics-name");
                    }
                    if (element.hasAttribute("data-analytics-type")) {
                        payload["creative_type"] = element.getAttribute("data-analytics-type");
                    }
                    if (element.hasAttribute("data-analytics-title")) {
                        payload["creative_title"] = element.getAttribute("data-analytics-title");
                    }
                    if (element.hasAttribute("data-analytics-placement")) {
                        payload["creative_placement"] = element.getAttribute("data-analytics-placement");
                    }
                    if (element.hasAttribute("data-analytics-position")) {
                        payload["creative_position"] = element.getAttribute("data-analytics-position");
                    }
                    if (element.hasAttribute("href")) {
                        const href = element.getAttribute("href");
                        if (href !== '#') {
                            payload["destination_url"] = href;
                        }
                    }

                    addDataLayerListener({
                        element,
                        event,
                        payload
                    });
                }
            })
        }
    });
}


// ... and joining old `Warframe` and new `window.Warframe`
window.Warframe = { ... window.Warframe, ... Warframe};


